import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import FullWidthImage from "../components/FullWidthImage";
import { getImage } from "gatsby-plugin-image";

// eslint-disable-next-line
export const WorkflowTemplate = ({
  description,
  title,
  helmet,
  featuredimage,
  tagline,
}) => {
  const heroImage = getImage(featuredimage) || featuredimage;
  const firstWordOfTitle = title.replace(/ .*/, "").toLowerCase();

  return (
    <section className="section workflow-page">
      {helmet || ""}
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <h1
              className="title is-size-2 has-text-weight-bold is-bold-light"
              style={{ color: "#4472c4", fontFamily: "Gotham - Light" }}
            >
              {title}
            </h1>
            <p className="title-description">{description}</p>
          </div>
          <small className="title-tagline">{tagline}</small>
          <div
            style={{
              position: "relative",
              width: "100vw",
              left: "calc(-50vw + 50%)",
            }}
          >
            <Link to={`/workflow-procedures/${firstWordOfTitle}`}>
              <FullWidthImage img={heroImage} />
            </Link>
          </div>
        </div>
        <div className="procedures">
          <div className="procedures-header d-flex">
            <h1>Procedures</h1>
          </div>
          {/* @todo map this out instead of hardcoding it. */}
          <div className="procedures-wrapper d-flex">
            <div className="diagnosis-planning d-flex">
              <div className="first procedurebox">
                <a>Diagnosis + Planning</a>
              </div>
              <div className="procedurebox">
                <Link
                  to={`/workflow-procedures/${firstWordOfTitle}/#3D-Imaging/Digital-Impression`}
                >
                  3D Imaging/Digital Impression
                </Link>
              </div>
              <div className="procedurebox">
                <Link
                  to={`/workflow-procedures/${firstWordOfTitle}/#Digital-Planning`}
                >
                  Digital Planning
                </Link>
              </div>
              <div className="procedurebox">
                <Link
                  to={`/workflow-procedures/${firstWordOfTitle}/#Guide-+-Provisional-Manufacture`}
                >
                  Guide + Provisional Manufacture
                </Link>
              </div>
            </div>
            <div className="surgery-temporization d-flex">
              <div className="first procedurebox">
                <a>Surgery + Temporization</a>
              </div>
              <div className="procedurebox">
                <Link
                  to={`/workflow-procedures/${firstWordOfTitle}/#Augmentation`}
                >
                  Augmentation
                </Link>
              </div>
              <div className="procedurebox">
                <Link
                  to={`/workflow-procedures/${firstWordOfTitle}/#Implant-Placement`}
                >
                  Implant Placement
                </Link>
              </div>
              <div className="procedurebox">
                <Link
                  to={`/workflow-procedures/${firstWordOfTitle}/#Temporization`}
                >
                  Temporization
                </Link>
              </div>
            </div>
            <div className="restoration d-flex">
              <div className="first procedurebox">
                <a>Restoration</a>
              </div>
              <div className="procedurebox">
                <Link
                  to={`/workflow-procedures/${firstWordOfTitle}/#Final-Impression`}
                >
                  Final Impression
                </Link>
              </div>
              <div className="procedurebox">
                <Link
                  to={`/workflow-procedures/${firstWordOfTitle}/#Final-Restoration`}
                >
                  Final Restoration
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

WorkflowTemplate.propTypes = {
  featuredimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
  tagline: PropTypes.string,
};

const workflow = ({ data }) => {
  const { markdownRemark: workflow } = data;

  return (
    <Layout>
      <WorkflowTemplate
        helmet={
          <Helmet titleTemplate="%s | Workflow">
            <title>{`${workflow.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${workflow.frontmatter.description}`}
            />
          </Helmet>
        }
        title={workflow.frontmatter.title}
        description={workflow.frontmatter.description}
        tagline={workflow.frontmatter.tagline}
        featuredimage={workflow.frontmatter.featuredimage}
      />
    </Layout>
  );
};

workflow.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default workflow;

export const workflowpageQuery = graphql`
  query WorkflowByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        description
        tagline
        featuredimage {
          publicURL
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;
